.btn {
  letter-spacing: 0.5px;

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    background-color: $gray-300;
    border-color: $gray-400;
    color: $gray-500;
  }

  @include media-breakpoint-down(sm) {
    padding: $btn-padding-y $btn-padding-x-sm;
    letter-spacing: 0;
  }
}

.btn-primary,
.btn-secondary,
.btn-tertiary,
.btn-link {
  &:focus,
  &.focus {
    box-shadow: 0 0 0 $input-btn-focus-width rgba($tertiary, 0.5) !important;
  }
}

.btn-secondary {
  border-color: $link-color;
  border-width: 1px;
  color: $link-color;
  background-color: rgba($link-color, 0.05);

  &:hover,
  &.hover {
    border-color: $link-color;
    background-color: rgba($link-color, 0.15);
    color: $link-color;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background-color: rgba($link-color, 0.15);
    border-color: $primary;
    color: $link-color;
  }
}

.btn-danger {
  background-color: rgba($danger, 0.05);
  color: $danger;
  border-width: 1px;

  &:hover,
  &.hover {
    border-color: $danger;
    background-color: rgba($danger, 0.15);
    color: $danger;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background-color: rgba($danger, 0.15);
    border-color: $danger;
    color: $danger;
  }
}

.btn-narrow {
  padding: $btn-padding-y 0.75rem;
}

.btn-sm {
  @include media-breakpoint-down(sm) {
    padding: $btn-padding-y-sm $btn-padding-x-sm;
  }
}

.btn-md {
  @include button-size($btn-padding-y-sm, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);

  @include media-breakpoint-down(sm) {
    padding: $btn-padding-y-sm $btn-padding-x-sm;
  }
}

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-base, $btn-line-height-lg, $btn-border-radius);
}

.btn-group,
.btn-group-vertical {
  // margin-right: 2px;

  > .btn {
    margin-right: 2px;

    &:last-child {
      margin-right: 0;
    }
  }

  > .icon {
    margin-top: calc($spacer / 2);
    margin-left: calc($spacer / 2);
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  &.hover {
    text-decoration: $link-hover-decoration;
  }

  &:disabled,
  &.disabled {
    text-decoration: none !important;
    background-color: transparent;
    border-color: transparent;
  }
}
