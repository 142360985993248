.page-header {
  margin-top: 0;
  margin-bottom: $spacer * 1.5;
  padding: 1rem 0;
  background: $gray-100;
  border: none;

  @include media-breakpoint-down(md) {
    margin-bottom: $spacer * 1.5;
    padding: 0.5rem 0;
  }
}

.page-title {
  margin-bottom: 0.5rem;

  @include media-breakpoint-down(xs) {
    font-size: 1.5rem;
  }
}

.page-title-sub {
  margin-top: 0;

  @include media-breakpoint-down(xs) {
    font-size: 1rem;
  }
}
