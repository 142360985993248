a[data-toggle="collapse"] {
  padding: 0.25rem 0.125rem;
}

.collapse-btn {
  width: 100%;
  position: relative;
  display: block;
  padding: calc($spacer / 2) 0 calc($spacer / 2) 1rem;
  text-align: left;
  color: $link-color;
  background-color: transparent;
  white-space: normal;

  &:hover,
  &:active {
    transform: translateY(0);
    color: $link-hover-color;
    text-decoration: underline;
  }

  &:focus {
    transform: translateY(0);
    text-decoration: underline;
    box-shadow: none;
  }

  &.collapsed {
    color: $link-color;

    .collapse-icon,
    .icon-more {
      transform: rotate(0deg);
    }

    &:hover,
    &:active {
      color: $link-hover-color;
    }
  }
}

.collapse-icon {
  position: absolute;
  top: calc(50% - 0.7rem);
  left: -0.5rem;
  color: $black;
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}

.collapse-body {
  padding: 0 $spacer * 1.25;
}

[data-toggle="collapse"] {
  &[aria-expanded="true"] {
    &.btn-secondary {
      background-color: $tertiary;
      color: $white;
      border-color: transparent;
    }

    .icon-close {
      display: inline-block;
    }

    .icon-help {
      display: none;
    }

    .icon-more,
    .icon-menu {
      display: none;
    }
  }

  &[aria-expanded="false"] {
    .icon-close {
      display: none;
    }

    .icon-more,
    .icon-menu {
      display: inline-block;
    }

    .icon-close-circle {
      display: none;
    }
  }
}

.collapse-sm:not(.show) {
  @include media-breakpoint-up(sm) {
    display: block !important;
  }
}

.collapse-md:not(.show) {
  @include media-breakpoint-up(md) {
    display: block !important;
  }
}

.collapse-lg:not(.show) {
  @include media-breakpoint-up(lg) {
    display: block !important;
  }
}

.accordion {
  [data-toggle="collapse"] {
    padding-left: 1.5rem;

    &[aria-expanded="true"] {
      background-color: $gray-100;
    }

    .icon {
      left: -0.15rem;
    }
  }
}
