.modal-header {
    align-items: center;
}

.modal-footer {
  display: flex;

  .btn {
    flex: 1;
  }
}

.modal-dialog-loading {
  width: 300px;

  .is-loading {
    height: 4rem;
    margin-bottom: 1rem;
  }
}