.help-panel-toggle {
  position: absolute;
  left: 0;
  bottom: 5rem;
  color: $white;
  transform: rotate(90deg);
  transform-origin: 0% 0%;
}

.help-panel {
  display: block !important;
  left: 100%;

  &.show {
    left: 0;
  }

  &.fade:not(.show) {
    opacity: 1;
  }
}

.help-panel-content {
  height: auto;
  bottom: 0;
  overflow-y: visible;
}
