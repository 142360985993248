@font-face {
  font-family: "Noto Sans";
  src: url("~@/assets/fonts/ltsa-NotoSans-Bold.woff2") format("woff2"),
    url("~@/assets/fonts/ltsa-NotoSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("~@/assets/fonts/ltsa-NotoSans-SemiBoldItalic.woff2") format("woff2"),
    url("~@/assets/fonts/ltsa-NotoSans-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Noto Sans";
  src: url("~@/assets/fonts/ltsa-NotoSans-MediumItalic.woff2") format("woff2"),
    url("~@/assets/fonts/ltsa-NotoSans-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Noto Sans";
  src: url("~@/assets/fonts/ltsa-NotoSans-Regular.woff2") format("woff2"),
    url("~@/assets/fonts/ltsa-NotoSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("~@/assets/fonts/ltsa-NotoSans-BoldItalic.woff2") format("woff2"),
    url("~@/assets/fonts/ltsa-NotoSans-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Noto Sans";
  src: url("~@/assets/fonts/ltsa-NotoSans-Italic.woff2") format("woff2"),
    url("~@/assets/fonts/ltsa-NotoSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Noto Sans";
  src: url("~@/assets/fonts/ltsa-NotoSans-SemiBold.woff2") format("woff2"),
    url("~@/assets/fonts/ltsa-NotoSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("~@/assets/fonts/ltsa-NotoSans-Medium.woff2") format("woff2"),
    url("~@/assets/fonts/ltsa-NotoSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
