.pagination {
  margin: 0;

  .icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.page-link {

  @include media-breakpoint-up(sm) {
      padding: $pagination-padding-y $pagination-padding-x*1.5;
  }
}