.step-wizard {
  position: relative;

  &:before {
    content: "";
    width: 1px;
    position: absolute;
    top: 1.25rem;
    bottom: 1.25rem;
    left: 8px;
    border-left: 2px solid $gray-200;
  }
}

.step {
  position: relative;
  font-size: $font-size-sm;
  line-height: $line-height-sm;

  &.active {
    & > .step-link {
      color: $black;
      font-weight: 700;

      &:before {
        width: 20px;
        height: 20px;
        top: calc(50% - 10px);
        left: calc(-2rem - 1px);
        background: $link-color;
      }
    }
  }

  &.disabled {
    .step-link {
      color: $gray-400;
      pointer-events: none;
    }
  }

  &.completed {
    .step-link {
      &:before {
        background-color: $tertiary;
      }
    }
  }

  .step {
    .step-link {
      padding: 0.35rem 1rem;
    }
  }
}

.step-link {
  display: flex;
  align-items: baseline;
  position: relative;
  margin-left: 2rem;
  padding: 1rem 0;

  &:before {
    content: "";
    position: absolute;
  }

  &:before {
    width: 12px;
    height: 12px;
    top: calc(50% - 6px);
    left: calc(-2rem + 3px);
    border-radius: 50%;
    background: $gray-300;
    z-index: 10;
  }
}
