.nav {
  font-size: $font-size-sm;

  // > li {

  //   > a {

  //     &:hover,
  //     &:focus {
  //       text-decoration: underline;
  //     }
  //   }
  // }

  //   // Open dropdowns
  // .open > a {
  //   &,
  //   &:hover,
  //   &:focus {
  //     background-color: transparent;
  //     border-color: transparent;
  //   }
  // }
}

.nav-link {
  @include media-breakpoint-down(sm) {
    padding: calc($spacer / 2);
  }
}

.navbar-nav {
  font-size: $font-size-sm;

  // > li {

  //   > a {

  //     &:hover,
  //     &:focus {
  //       text-decoration: none;
  //     }

  //     &:focus {
  //       outline: none !important;
  //     }
  //   }
  // }
}

.nav-tabs {
  .nav-link {
    // padding: $spacer;
    hyphens: auto;
    line-height: $line-height-sm;

    &:focus {
      outline: 0;
    }

    &.active {
      font-weight: 700;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    @include media-breakpoint-up(md) {
      min-width: 8rem;
      padding: $spacer * 1.25 $spacer * 1 $spacer * 1 $spacer * 1;
      border-bottom-color: transparent;
      text-align: center;
    }
  }

  .dropdown-toggle {
    &.active {
      font-weight: 400;
      color: $link-color;

      @include media-breakpoint-down(sm) {
        position: relative;

        &:before {
          content: none;
        }
      }
    }
  }

  .dropdown-menu {
    border: 1px solid $nav-tabs-border-color;
  }
}

// On this page
.on-this-page {
  margin-top: calc($spacer / 1.5);
}

.on-this-page-title {
  display: inline-block;
  vertical-align: top;
  margin-top: 0;
  font-weight: 700;
  color: $gray-600;
}

.on-this-page-anchor-list {
  display: inline-block;
  list-style-type: none;
  margin-left: 0;

  a {
    display: block;
    padding-bottom: calc($spacer / 4);
    border-bottom: none;
  }
}

.scroll-to-top {
  margin: 3rem 0 2rem 0;

  .icon {
    margin-right: 0.5rem;
    background-color: $tertiary;
    color: $white;
    border-radius: 50%;
  }
}
