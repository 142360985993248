.badge-tab {
  font-size: 85%;

  @include media-breakpoint-up(md) {
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    border-radius: 0;
  }
}
