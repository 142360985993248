.ng-select.custom .ng-select-container {
  border-radius: $border-radius;
  border: $border-width solid $input-border-color;
  min-height: 36px;
}

.ng-select.custom .ng-select-container:hover {
  border: $border-width solid $input-border-color;
}

.ng-select.custom.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 0.45rem;
  padding-left: 0.5rem;
}

.ng-select.custom.ng-select-focused:not(.ng-select-opened)
  > .ng-select-container {
  border-color: $gray-700;
  box-shadow: none;
}

.ng-select.custom.ng-select-opened > .ng-select-container {
  background: #fff;
  border-color: $gray-700 $gray-700 $gray-700;
}

.ng-select.custom .ng-select-container .ng-value-container .ng-placeholder {
  color: $input-placeholder-color;
}

.ng-select.custom.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  top: 0.5rem;
  padding-bottom: 0.45rem;
  padding-left: 0.25rem;
}

.ng-select.custom.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding-bottom: 0.45rem;
  padding-left: 0.25rem;
}

.ng-select.custom .ng-dropdown-panel {
  border: 1px solid $gray-700;
  box-shadow: none;
}

.ng-select.custom .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  padding: 0.5rem 0.75rem;
  font-weight: 600;
  color: $gray-700;
  font-size: 0.875rem;
}

.ng-select.custom
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked,
.ng-select.custom
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-optgroup.ng-option-marked,
.ng-select.custom
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected,
.ng-select.custom
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-optgroup.ng-option-selected {
  background-color: $gray-100;
  color: $gray-700;
}

.ng-select.custom .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: $gray-700 transparent transparent;
}

.ng-select.custom .ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent $gray-700;
}

.ng-select.custom
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.left {
  border-right: 1px solid white;
  color: $link-color;
}

.ng-select.custom
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon,
.ng-select.custom
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  padding: 0.125rem 0.5rem;
}

.ng-select.custom
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: $gray-200;
  color: $gray-700;
  border: 1px solid white;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.left {
  border-right: 1px solid white;
  color: #3858a4;
}
