// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

//
// Color system @modified
//

// stylelint-disable
$white: #fff !default;
$gray-100: #f1f4f7 !default;
$gray-200: #e5ebf0 !default;
$gray-300: #c9d4db !default;
$gray-400: #adb6bc !default;
$gray-500: #697177 !default;
$gray-600: #474b56 !default;
$gray-700: #2f353b !default;
$gray-800: #2f353b !default;
$gray-900: #2f353b !default;
$black: #000 !default;

$brand: #3858a4 !default;
$blue-dark: #346caf !default;
$blue: #3b9cef !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #cc1003 !default;
$orange: #fb8229 !default;
$yellow: #f9ba31 !default;
$green: #8abf1c !default;
$teal: #20c997 !default;
$cyan: rgba($blue, 0.15) !default;

$colors: () !default;
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
  ),
  $colors
);

$primary: $brand !default;
$secondary: $gray-200 !default;
$tertiary: $blue !default;
$success: $green !default;
$info: $tertiary !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "tertiary": $tertiary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "medium": $gray-200,
    "medium-dark": $gray-300,
    "dark": $dark,
  ),
  $theme-colors
);
// stylelint-enable

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 160 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true !default;
$enable-print-styles: true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.5,
    ),
    2: (
      $spacer,
    ),
    3: (
      $spacer * 1.5,
    ),
    4: (
      $spacer * 2,
    ),
    5: (
      $spacer * 3,
    ),
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge(
  (
    5: 5%,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
  ),
  $sizes
);
// stylelint-enable

// Pixel sizes for truncating strings
$pixelsizes: () !default;
$pixelsizes: map-merge(
  (
    100: 100px,
    200: 200px,
    300: 300px,
  ),
  $pixelsizes
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $gray-900 !default;

// Links
//
// Style anchor elements.

$link-color: $brand !default;
$link-decoration: none !default;
$link-hover-color: $tertiary !default;
$link-hover-decoration: underline !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 520px,
  md: 800px,
  lg: 1080px,
  xl: 1400px,
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 490px,
  md: 720px,
  lg: 960px,
  xl: 1260px,
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

$fluid-container-max-width: 1260px;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 40px !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5 !default;
$line-height-sm: 1.25 !default;

$border-width: 1px !default;
$border-color: $gray-300 !default;

$border-radius: 2px !default;
$border-radius-lg: 0.25rem !default;
$border-radius-sm: 0.05rem !default;

$component-active-color: $white !default;
$component-active-bg: $tertiary !default;

$caret-width: 0.3em !default;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

// Fonts @modified
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;

$font-weight-light: 400 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.15 !default;
$h4-font-size: $font-size-base !default;
$h5-font-size: $font-size-sm !default;
$h6-font-size: $font-size-sm !default;

$headings-margin-bottom: ($spacer) !default;
$headings-font-family: inherit !default;
$headings-font-weight: 700 !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 400 !default;
$display2-weight: 400 !default;
$display3-weight: 400 !default;
$display4-weight: 400 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: ($font-size-base * 1.125) !default;
$lead-font-weight: 400 !default;

$small-font-size: $font-size-sm !default;

$text-muted: $gray-600 !default;

$blockquote-small-color: $gray-600 !default;
$blockquote-font-size: ($font-size-base * 1.25) !default;

$hr-border-color: rgba($black, 0.1) !default;
$hr-border-width: $border-width !default;

$mark-padding: 0.2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 1rem !default;

$mark-bg: #fdf5ce !default;

$hr-margin-y: $spacer !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.65rem !default;
$table-cell-padding-lg: 1rem !default;
$table-cell-padding-sm: 0.3rem !default;

$table-bg: transparent !default;
$table-accent-bg: rgba($black, 0.05) !default;
$table-hover-bg: $gray-100 !default;
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: $border-color !default;

$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;

$table-dark-bg: $gray-900 !default;
$table-dark-accent-bg: rgba($white, 0.05) !default;
$table-dark-hover-bg: rgba($white, 0.075) !default;
$table-dark-border-color: lighten($gray-900, 7.5%) !default;
$table-dark-color: $body-bg !default;

// Buttons + Forms @customized
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 1.5rem !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: 3px !default;
$input-btn-focus-color: rgba($component-active-bg, 0.5) !default;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-line-height-sm: 1.5 !default;

$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 3rem !default;
$input-btn-line-height-lg: $line-height-lg !default;

$input-btn-border-width: $border-width !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-line-height: $input-btn-line-height !default;

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-font-weight: $font-weight-normal !default;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075) !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 1 !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125) !default;

$btn-link-disabled-color: $gray-600 !default;

$btn-block-spacing-y: 0.5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

// Forms

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: 0.5rem !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-line-height-sm: $input-btn-line-height-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-line-height-lg: $input-btn-line-height-lg !default;

$input-bg: $white !default;
$input-disabled-bg: $gray-200 !default;

$input-color: $gray-700 !default;
$input-border-color: $gray-400 !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: inset 0 1px 1px rgba($black, 0.075) !default;

$input-border-radius: $border-radius !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: $gray-400 !default;
$input-focus-color: $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$input-placeholder-color: $gray-600 !default;

$input-height-border: $input-border-width * 2 !default;

$input-height-inner: ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
$input-height: calc(#{$input-height-inner} + #{$input-height-border}) !default;

$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
$input-height-sm: calc(#{$input-height-inner-sm}) !default;

$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

$input-transition: none;

$form-text-margin-top: 0.25rem !default;

$form-check-input-gutter: 1.25rem !default;
$form-check-input-margin-y: 0.3rem !default;
$form-check-input-margin-x: 0.25rem !default;

$form-check-inline-margin-x: 0.75rem !default;
$form-check-inline-input-margin-x: 0.3125rem !default;

$form-group-margin-bottom: 1.25rem !default;

$input-group-addon-color: $gray-500 !default;
$input-group-addon-bg: $gray-100 !default;
$input-group-addon-border-color: $input-border-color !default;

$custom-control-gutter: 1.75rem !default;
$custom-control-spacer-x: 1.75rem !default;

$custom-control-indicator-size: 1.35rem !default;
$custom-control-indicator-bg: $white !default;
$custom-control-indicator-bg-size: 50% 50% !default;
$custom-control-indicator-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1) !default;

$custom-control-indicator-disabled-bg: $gray-200 !default;
$custom-control-label-disabled-color: $gray-600 !default;

$custom-control-indicator-checked-color: $white !default;
$custom-control-indicator-checked-bg: $tertiary !default;
$custom-control-indicator-checked-disabled-bg: rgba(theme-color("primary"), 0.5) !default;
$custom-control-indicator-checked-box-shadow: none !default;

$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg, $input-btn-focus-box-shadow !default;

$custom-control-indicator-active-color: $component-active-color !default;
$custom-control-indicator-active-bg: lighten($component-active-bg, 35%) !default;
$custom-control-indicator-active-box-shadow: none !default;

$custom-checkbox-indicator-border-radius: $border-radius !default;
$custom-checkbox-indicator-icon-checked: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;

$custom-checkbox-indicator-indeterminate-bg: $component-active-bg !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;

$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-select-padding-y: 0.375rem !default;
$custom-select-padding-x: 0.75rem !default;
$custom-select-height: $input-height !default;
$custom-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height: $input-btn-line-height !default;
$custom-select-color: $input-color !default;
$custom-select-disabled-color: $gray-600 !default;
$custom-select-bg: $white !default;
$custom-select-disabled-bg: $gray-200 !default;
$custom-select-bg-size: 8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: $gray-800 !default;
$custom-select-indicator: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-select-border-width: $input-btn-border-width !default;
$custom-select-border-color: $input-border-color !default;
$custom-select-border-radius: $border-radius !default;

$custom-select-focus-border-color: $input-focus-border-color !default;
$custom-select-focus-box-shadow: inset 0 1px 2px rgba($black, 0.075), 0 0 5px rgba($custom-select-focus-border-color, 0.5) !default;

$custom-select-font-size-sm: 75% !default;
$custom-select-height-sm: $input-height-sm !default;

$custom-select-font-size-lg: 125% !default;
$custom-select-height-lg: $input-height-lg !default;

$custom-file-height: $input-height !default;
$custom-file-focus-border-color: $input-focus-border-color !default;
$custom-file-focus-box-shadow: $input-btn-focus-box-shadow !default;

$custom-file-padding-y: $input-btn-padding-y !default;
$custom-file-padding-x: $input-btn-padding-x !default;
$custom-file-line-height: $input-btn-line-height !default;
$custom-file-color: $input-color !default;
$custom-file-bg: $input-bg !default;
$custom-file-border-width: $input-btn-border-width !default;
$custom-file-border-color: $input-border-color !default;
$custom-file-border-radius: $input-border-radius !default;
$custom-file-box-shadow: $input-box-shadow !default;
$custom-file-button-color: $custom-file-color !default;
$custom-file-button-bg: $input-group-addon-bg !default;
$custom-file-text: (
  en: "Browse",
) !default;

// Form validation
$form-feedback-margin-top: $form-text-margin-top !default;
$form-feedback-font-size: $small-font-size !default;
$form-feedback-valid-color: theme-color("success") !default;
$form-feedback-invalid-color: theme-color("danger") !default;

// Switch
$switch-height: calc(#{$input-height} * 0.5) !default;
$switch-border-radius: $border-radius !default;
$switch-bg: $gray-400 !default;
$switch-border: $input-border-color !default;
$switch-checked-bg: map-get($theme-colors, "tertiary") !default;
$switch-disabled-bg: $gray-300 !default;
$switch-disabled-color: $gray-600 !default;
$switch-thumb-bg: $white !default;
$switch-thumb-border-radius: $border-radius !default;
$switch-thumb-padding: 1px !default;
$switch-focus-box-shadow: 0 0 0 $input-btn-focus-width rgba(map-get($theme-colors, "tertiary"), 0.25);
$switch-transition: 0.2s all !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: $border-color !default;
$dropdown-border-radius: $border-radius !default;
$dropdown-border-width: $border-width !default;
$dropdown-divider-bg: $gray-200 !default;
$dropdown-box-shadow: none !default;

$dropdown-link-color: $gray-900 !default;
$dropdown-link-hover-color: darken($gray-900, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

$dropdown-link-disabled-color: $gray-600 !default;

$dropdown-item-padding-y: 0.35rem !default;
$dropdown-item-padding-x: 1.25rem !default;

$dropdown-header-color: $gray-600 !default;

// Navbar @modified
$navbar-dark-color: $white !default;
$navbar-dark-hover-color: rgba($white, 0.75) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;

// Pagination
$pagination-padding-x: 0.5rem !default;

// Cards

$card-spacer-y: 0.75rem !default;
$card-spacer-x: 1rem !default;
$card-border-width: $border-width !default;
$card-border-radius: $border-radius !default;
$card-border-color: $gray-300 !default;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg: $white !default;
$card-bg: $white !default;

$card-img-overlay-padding: 1.25rem !default;

$card-group-margin: calc($grid-gutter-width / 2) !default;
$card-deck-margin: $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

$card-selectable-icon: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$tertiary}' viewBox='0 0 24 24'%3E%3Cpath d='M9.984 17.016l9-9-1.406-1.453-7.594 7.594-3.563-3.563-1.406 1.406zM12 2.016c5.531 0 9.984 4.453 9.984 9.984s-4.453 9.984-9.984 9.984-9.984-4.453-9.984-9.984 4.453-9.984 9.984-9.984z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;

// Tooltips

$tooltip-font-size: $font-size-sm !default;
$tooltip-max-width: 200px !default;
$tooltip-color: $white !default;
$tooltip-bg: $black !default;
$tooltip-border-radius: $border-radius !default;
$tooltip-opacity: 0.9 !default;
$tooltip-padding-y: 0.25rem !default;
$tooltip-padding-x: 0.5rem !default;
$tooltip-margin: 0 !default;

$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;

// Popovers

// $popover-font-size:                 $font-size-sm !default;
$popover-bg: $white !default;
// $popover-max-width:                 276px !default;
// $popover-border-width:              $border-width !default;
// $popover-border-color:              $border-color !default;
// $popover-border-radius:             $border-radius !default;
// $popover-box-shadow:                0 .25rem .5rem rgba($black, .2) !default;

// $popover-header-bg:                 $popover-bg !default;
// $popover-header-color:              $headings-color !default;
// $popover-header-padding-y:          .75rem !default;
// $popover-header-padding-x:          .75rem !default;

// $popover-body-color:                $body-color !default;
// $popover-body-padding-y:            $popover-header-padding-y !default;
// $popover-body-padding-x:            $popover-header-padding-x !default;

// $popover-arrow-width:               1rem !default;
// $popover-arrow-height:              .5rem !default;
// $popover-arrow-color:               $popover-bg !default;

// $popover-arrow-outer-color:         fade-in($popover-border-color, .05) !default;

// Modals @modified
$modal-content-border-color: transparent !default;
$modal-backdrop-bg: $gray-600 !default;
$modal-header-border-color: transparent !default;
$modal-footer-border-color: $gray-300 !default;
$modal-header-padding: 1rem 1rem 0 1rem !default;

// $modal-lg:                          800px !default;
// $modal-md:                          400px !default;
// $modal-sm:                          200px !default;

// Alerts
//
// Define alert colors, border radius, and padding.
$alert-bg-level: -11 !default;
$alert-border-level: 0 !default;
$alert-color-level: 6 !default;

// Toasts
$toast-padding-y: 1rem !default;
$toast-padding-x: 1.75rem !default;
$toast-border-radius: $border-radius !default;
$toast-border-width: $border-width !default;
$toast-box-shadow: 0.5rem 0.5rem 2rem rgba($gray-600, 0.175) !default;

$toast-bg-level: 0 !default;
$toast-border-level: 0 !default;
$toast-color-level: -10 !default;

// Breadcrumbs @modified

$breadcrumb-padding-y: 0.5rem !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding: 0.75rem !default;

$breadcrumb-margin-bottom: 1rem !default;

$breadcrumb-divider-color: $gray-500 !default;
$breadcrumb-active-color: $gray-500 !default;
$breadcrumb-divider: quote("/") !default;

// Badges @modified

$badge-font-size: 80% !default;
$badge-font-weight: $font-weight-bold !default;
$badge-padding-y: 0.4em !default;
$badge-padding-x: 0.4em !default;
$badge-border-radius: $border-radius !default;

$badge-transition: $btn-transition !default;
$badge-focus-width: $input-btn-focus-width !default;

$badge-pill-padding-x: 0.6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem !default;
