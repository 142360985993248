.is-loading,
.is-loading-inline,
.is-loading-fullscreen {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    z-index: 20000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($white, 0.75);
    animation: spinner-bg 1s forwards ease;
  }

  &:after {
    content: "";
    z-index: 20010;
    position: absolute;
    width: 4em;
    height: 4em;
    top: 50%;
    left: 50%;
    margin: -2rem;
    font-size: rem(10px);
    overflow: hidden;
    text-indent: -9999em;
    border-top: 0.35em solid $gray-200;
    border-right: 0.35em solid $gray-200;
    border-bottom: 0.35em solid $gray-200;
    border-left: 0.35em solid $tertiary;
    border-radius: 50%;
    animation: spinner 0.75s infinite ease;
  }
}

.is-loading-fullscreen {
  &:before,
  &:after {
    position: fixed;
  }
}

.is-loading-inline {
  // padding-left: $spacer * 3.25;

  &:after {
    width: 1.5em;
    height: 1.5em;
    top: 50%;
    left: -1em;
    margin: -0.75rem;
    border-top: 0.2em solid $gray-200;
    border-right: 0.2em solid $gray-200;
    border-bottom: 0.2em solid $gray-200;
    border-left: 0.2em solid $tertiary;
  }
}

.is-spinning {
  animation: spinner 0.75s infinite linear;
}

@keyframes spinner-bg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
