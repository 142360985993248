@import "./node_modules/bootstrap/scss/functions";
@import "ltsa-styles/ltsa-variables";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/bootstrap";

// LTSA Bootstrap overrides
@import "ltsa-styles/fonts";
@import "ltsa-styles/type";
@import "ltsa-styles/grid";
@import "ltsa-styles/buttons";
@import "ltsa-styles/cards";
@import "ltsa-styles/collapse";
@import "ltsa-styles/drawer";
@import "ltsa-styles/dropdowns";
@import "ltsa-styles/breadcrumbs";
@import "ltsa-styles/pagination";
@import "ltsa-styles/list-group";
@import "ltsa-styles/loading";
@import "ltsa-styles/alerts";
@import "ltsa-styles/forms";
@import "ltsa-styles/file-upload";
@import "ltsa-styles/switch";
@import "ltsa-styles/tables";
@import "ltsa-styles/modals";
@import "ltsa-styles/navs";
@import "ltsa-styles/popovers";
@import "ltsa-styles/tabs";
@import "ltsa-styles/toast";
@import "ltsa-styles/step-wizard";
@import "ltsa-styles/multiselect";
@import "ltsa-styles/help-panel";
@import "ltsa-styles/page-header";
@import "ltsa-styles/utilities";

.print-preview {
  max-width: 860px;
}
