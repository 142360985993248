fieldset {
  margin-bottom: $spacer * 1.5;
  padding-bottom: $spacer * 1;
}

legend {
  margin-top: $spacer;
  margin-bottom: $spacer;
  font-size: 1.125rem;
  font-weight: bold;
  border-bottom: none;
}

label {
  margin-bottom: 0.25rem;
  font-weight: 500;
  font-size: 0.925rem;
}

input[type="file"].form-control {
  height: auto;
}

.form-text {
  font-size: $font-size-sm;
}

.input-group-text {
  background-color: $white;
}

.input-group > .form-control-sm {
  height: 2rem;
}

.form-control {
  &:hover {
    border-color: $gray-700;
  }
}

// Form validation
.is-valid,
.is-invalid {
  position: relative;
  border-left-width: 5px;

  .invalid-feedback {
    display: block;
  }

  // &:before {
  //   content: '';
  //   width: 4px;
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: - $spacer / 1.25;
  //   background: $danger;
  // }

  .custom-control-label::before {
    border: 2px solid $danger;
  }
}

.is-valid {
  &:before {
    background: $success;
  }

  .custom-control-label::before {
    border: 1px solid $success;
  }
}

// Radio buttons
.radio-btn-group {
  .radio-btn {
    display: inline-block;
    margin-left: -1px;

    label {
      max-width: 14rem;
    }

    &:first-child {
      margin-left: 0;

      label {
        border-radius: $btn-border-radius 0 0 $btn-border-radius;
      }
    }

    &:last-child {
      label {
        border-radius: 0 $btn-border-radius $btn-border-radius 0;
      }
    }
  }
}

.radio-btn {
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:focus {
      & + label {
        outline: 2px dotted $gray-400;
        outline-offset: 1px;
      }
    }

    &:disabled {
      & + label {
        cursor: not-allowed;
        color: $gray-400;
      }
    }

    &:checked {
      & + label {
        color: $white;
        background-color: $tertiary;
        border: 1px solid $tertiary;

        &:after {
          content: "";
          width: 8px;
          height: 8px;
          position: absolute;
          background-color: $tertiary;
          border-radius: 50%;
          top: calc(50% - 4px);
          left: 16px;
        }

        &:before {
          background-color: $white;
        }
      }
    }
  }

  label {
    position: relative;
    margin-right: 0.5rem;
    padding: 10px 16px 10px 38px;
    font-weight: normal;
    line-height: $line-height-sm;
    border: 1px solid $input-border-color;
    border-radius: $border-radius;
    background-color: $white;
    user-select: none;

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      background-color: $gray-300;
      border-radius: 50%;
      top: calc(50% - 8px);
      left: 12px;
    }
  }
}

.custom-radio,
.custom-checkbox {
  margin-bottom: 0.5rem;
  padding-left: 0;

  .custom-control-label {
    padding-left: 2rem;
    font-weight: normal;

    &:before {
      left: 0;
      transition: none;
    }

    &:after {
      left: 0;
    }

    &:hover {
      cursor: pointer;

      &:before {
        border-color: $gray-700;
      }
    }
  }
}

// Custom controls
.custom-control-label::before {
  border: 1px solid $input-border-color;
}

// .custom-control-input:focus ~ .custom-control-label::before {
//     outline: 2px dotted $gray-400;
//     outline-offset: 1px;
// }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label,
.custom-radio .custom-control-input:checked ~ .custom-control-label,
.custom-checkbox .custom-control-input:disabled ~ .custom-control-label,
.custom-radio .custom-control-input:disabled ~ .custom-control-label {
  &:before {
    border: none;
  }
}

.custom-select {
  -webkit-appearance: none;
}

.input-group-prepend {
  .custom-select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
