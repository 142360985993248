.list-unstyled {

  li {
    padding-bottom: 0.25rem;
  }
}

.list-group {
  margin-bottom: $spacer * 1.5;
  font-size: $font-size-sm;
}

a.list-group-item,
button.list-group-item {

  // Hover state
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.list-group-indented {

  .list-group-item {
    padding: $spacer * 0.75 0 $spacer * 0.75 3rem;
    border-right: 0;
    border-left: 0;
    @include border-radius(0);
  }

  // &:first-child {
  //   .list-group-item:first-child {
  //     border-top: 0;
  //   }
  // }

  &:last-child {
    .list-group-item:last-child {
      border-bottom: 0;
    }
  }
}

nav.flex-column .nav-link {
  position: relative;

  &.active,
  &.active:hover,
  &.active:focus {
    font-weight: 700;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 3px;
      background: $link-color;
    }
  }
}

