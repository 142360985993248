.table {
  font-size: $font-size-sm !important;

  thead th {
    border-bottom-width: 1px;
    line-height: $line-height-sm;
  }

  th {
    letter-spacing: 0.5px;
    color: $black;
    // white-space: nowrap;
    border: none;
  }

  th,
  td {
    vertical-align: baseline;
  }

  &.table-full {
    th,
    td {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .label {
    margin-right: 2px;
    display: inline-block;
    padding: 0 0.35em;
    font-size: 100%;
    position: relative;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    border-radius: 50%;
  }

  .icon {
    width: 1.1rem;
    height: 1.1rem;
  }

  .form-control,
  label {
    font-size: $font-size-sm;
  }

  &.table-brand-primary {
    border-top: none;

    thead {
      background-color: $tertiary;
    }

    th {
      color: $white;
      border-bottom: none;
    }
  }

  &.table-brand-secondary {
    border-top: none;

    thead {
      background-color: $green;
    }

    th {
      color: $white;
      border-bottom: none;
    }
  }

  .custom-radio,
  .custom-checkbox {
    margin-bottom: 0rem;
  }
}

.table-lg {
  th,
  td {
    padding: $table-cell-padding-lg;
    // font-size: $font-size-base;
  }
}

.active-sort {
  background-color: rgba($gray-600, 0.1);
}

.table-sort {
  width: 0 !important;
  height: 0 !important;
  display: inline-block;
  float: none;
  margin: 2px 0 3px 3px;
  font-size: 0.75rem;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-top: 7px solid $gray-600;
  vertical-align: middle;
  content: "";
  background-color: transparent;
  opacity: 0.5;
}

.table-sort--up {
  opacity: 1;
  transform: rotate(180deg);
}

.table-sort--down {
  opacity: 1;
}

.table-sticky {
  th[scope="row"],
  td[scope="row"] {
    @include media-breakpoint-down(md) {
      position: absolute;
      width: 7rem;
      left: calc($grid-gutter-width / 2);
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @include media-breakpoint-down(md) {
    overflow-x: auto;
    margin-left: 7rem;
  }
}

@include media-breakpoint-down(sm) {
  .table-stacked {
    display: block;
    margin: 0 auto;
    width: 100%;
    border-spacing: 0;
  }

  .table-stacked thead {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }

  .table-stacked tbody,
  .table-stacked tr,
  .table-stacked th,
  .table-stacked td {
    display: block;
    text-align: left;
    white-space: normal;
  }

  .table-stacked tr {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $table-border-color;

    &:first-child {
      border-top: 1px solid $border-color;
    }
  }

  .table-stacked td,
  .table-stacked th {
    min-height: 2rem;
    padding: 0.15rem 0.25rem;
    vertical-align: top;
    vertical-align: top;
    border-bottom: none;
    border-top: none;
    width: 100%;
    white-space: normal;
    clear: both;
  }

  .table-stacked th[scope="row"] {
    width: 100%;
    text-align: center;
    display: block;
    background-color: $gray-200;
    margin: 0 auto;
    padding: 0.5rem 0;
  }

  .table-stacked td[scope="row"] {
    padding: 0.25rem 0.25rem;
    font-size: $font-size-base;
    background-color: $gray-200;
  }

  .table-stacked td[data-header]:before {
    content: attr(data-header);
    display: block;
    float: left;
    width: 50%;
    color: $black;
    font-weight: bold;
    text-align: left;
  }

  .table-stacked .td-content {
    width: 50%;
    float: right;
    clear: right;
  }

  .table-stacked td {
    ul {
      width: 50%;
      float: right;
    }
  }

  .table-stacked-col-full td,
  .table-stacked-col-full td {
    margin-bottom: 0.5rem;
  }

  .table-stacked-col-full td[data-header]:before {
    float: none;
    width: 100%;
  }

  .table-stacked-col-full .td-content {
    width: 100%;
    float: none;
  }

  .table-select-row {
    tr {
      position: relative;
    }

    td[scope="row"] {
      width: 2rem;
      position: absolute;
      padding-left: 0.25rem !important;
      padding-top: 0.25rem;
      left: 0;
      background-color: transparent !important;
    }

    td {
      padding-left: 2rem !important;
    }
  }
}
