.dropdown-menu-grid {
  border: none;
  margin-left: 1px;
  font-size: $font-size-sm;

  .border {
    margin-left: -1px;
  }
}

.dropdown-toggle-split {
  margin-left: -3px !important;
}