.toast {
  position: fixed;
  left: 50%;
  background: $success;
  z-index: $zindex-popover;
  padding: $toast-padding-y $toast-padding-x;
  border: $toast-border-width solid transparent;
  @include border-radius($toast-border-radius);
  box-shadow: $toast-box-shadow;
  transform: translateX(-50%);
  text-align: center;

  &.toast-top {
    top: 2rem;
  }

  &.toast-bottom {
    bottom: 2rem;
  }
}

@each $color, $value in $theme-colors {
  .toast-#{$color} {
    @include alert-variant(theme-color-level($color, $toast-bg-level), theme-color-level($color, $toast-border-level), theme-color-level($color, $toast-color-level));
  }
}

.toast-light {
  color: $black;
}
