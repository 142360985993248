.drop {
  box-sizing: border-box;
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 1rem;
  top: 0%;
  left: 0%;
  border: 2px dashed $input-border-color;
  background-color: $white;
}

.droppable {
  border: 2px dashed $tertiary;
}

.dropzone {
  opacity: 0;
  width: 100%;
  height: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
