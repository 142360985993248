.drawer {
  position: fixed;
  display: none;
  z-index: 1050;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.show {

    .left {
      transform: translateX(20rem);
    }

    .right {
      transform: translateX(-20rem);
    }
  }
}

.drawer-content {
  width: 20rem;
  height: 100%;
  position: absolute;
  left: 100%;
  overflow-y: auto;
  padding: $spacer;
  background: $white;
  box-shadow: 10px 0 50px rgba($gray-400, 0.3);
  transition: transform .3s ease;

  &.left {
    left: -20rem;
  }

  &.right {
    left: 100%;
  }
}

.drawer-lg {

  .drawer-content {

    @include media-breakpoint-up(lg) {
      width: auto;
      position: static;
      padding: 0;
      box-shadow: none;
      overflow: inherit;
    }
  }

  @include media-breakpoint-up(lg) {
    display: block;
    position: static;
    opacity: 1 !important;
  }
}

.drawer-md {

  .drawer-content {

    @include media-breakpoint-up(md) {
      width: auto;
      position: static;
      padding: 0;
      box-shadow: none;
      overflow: inherit;
    }
  }

  @include media-breakpoint-up(md) {
    display: block;
    position: static;
    opacity: 1 !important;
  }
}

.drawer-sm {

  .drawer-content {

    @include media-breakpoint-up(sm) {
      width: auto;
      position: static;
      padding: 0;
      box-shadow: none;
      overflow: inherit;
    }
  }

  @include media-breakpoint-up(sm) {
    display: block;
    position: static;
    opacity: 1 !important;
  }
}
