.border-bottom-lg {
  border-bottom-width: 3px !important;
}

.text-md-white {
  @include media-breakpoint-down(sm) {
    color: $white !important;
  }
}

.bg-md-dark {
  @include media-breakpoint-down(sm) {
    background-color: $dark !important;
  }
}

.btn-md-white {
  @include media-breakpoint-down(sm) {
    background-color: transparent !important;
    color: $white !important;
    border-color: $white !important;
  }
}

.text-break-spaces {
  white-space: break-spaces !important;
}
