.card {
  // font-size: $font-size-sm;
  border-bottom-width: 3px;
}

.bg-pending {
  background: repeating-linear-gradient(
    -45deg,
    rgba($gray-300, 0.6),
    rgba($gray-300, 0.6) 10px,
    $gray-100 10px,
    $gray-100 20px
  )
}

.card-footer {
  padding: 0.5rem $card-spacer-x;

  .btn {
    padding-top: 0;
    padding-bottom: 0;

    &:first-child {
      padding-left: 0;
    }
  }
}

.card-selectable {

  .card {
    user-select: none;

    .card-title {
      padding-right: 1.5rem;
    }

    &:after {
      width: 1.25rem;
      height: 1.25rem;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }

    &:hover {
      cursor: pointer;
      // background-color: rgba($tertiary, 0.1);
      border-color: rgba($tertiary, 0.75);

      &:after {
        content: $card-selectable-icon;
        opacity: 0.5;
      }
    }
  }

  input:checked {

    + .card {
      border-color: $tertiary;
      box-shadow: inset 0 0 0 2px $tertiary;

      &:hover {
        background-color: transparent;

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: $card-selectable-icon;
      }
    }
  }
}