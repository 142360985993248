.popover {
  @include box-shadow(0 10px 30px rgba($gray-500,.2));
}

.popover-header {
    padding-bottom: 0;
    border-bottom: none;
}

.popover-title {
  padding: 8px 14px 0 14px;
  border-bottom: none;
}
