.alert {
  position: relative;
  margin-bottom: 2rem;
  padding-left: 3.5rem;
  border-top-width: 0;
  border-bottom-width: 0;
  border-right-width: 0;
  border-left-width: 6px;
  border-radius: 0;
  color: $black;

  .icon-close {
    fill: $black !important;
  }
}

.alert-icon {
  position: absolute;
  left: 1rem;

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.alert-success {

  .icon {
    fill: $success;
  }
}

.alert-primary {

  .icon {
    fill: $primary;
  }
}

.alert-danger {

  .icon {
    fill: $danger;
  }
}

.alert-info {

  .icon {
    fill: $tertiary;
  }
}

.alert-warning {

  .icon {
    fill: $warning;
  }
}

.alert-neutral {
  border-left-color: $tertiary;
  padding-left: 1.5rem;
}

.disclaimer-line {

  .icon {
    margin-right: 0.25rem;
  }
}
