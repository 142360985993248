//
// Breadcrumbs
// --------------------------------------------------

.breadcrumb {
  background: transparent;
  font-size: $font-size-sm;

  > .active {
    font-weight: 400;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 0.5rem;
  }
}
