// @note: Bootstrap overrides

a:focus,
a.focus {
  outline: 2px solid rgba($tertiary, 0.5);
  background-color: rgba($tertiary, 0.05);
}

h1 {
  letter-spacing: 0.5px;
}

h2,
h3 {
  letter-spacing: 0.25px;
}

h4 {
  letter-spacing: 0.25px;
}

b,
strong {
  font-weight: 700;
}

hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-top-color: $gray-500;
}

mark {
  padding: 0 0.3rem;
  font-weight: 600;
}

// @note: LTSA specific styles

.link-glossary {
  border-bottom: 2px dotted $link-color;
  cursor: help;
  text-decoration: none;
}

.icon {
  display: inline-block;
  flex-shrink: 0;
  vertical-align: middle;
  margin-top: -2px;
  width: 1.4rem;
  height: 1.4rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.icon-date {
  color: $link-color;
}

.icon-sm {
  width: 1rem;
  height: 1rem;
}

.icon-lg {
  width: 2rem !important;
  height: 2rem !important;
}

.icon-inline {
  bottom: 0.7rem;
  left: 1.6rem;
  color: $gray-400;
}

.icon-inline-right {
  bottom: 0.65rem;
  right: 0.75rem;
  z-index: 100;
  pointer-events: none;
}

.icon-more {
  transition: $transition-base;
}

img {
  width: 100%;
  height: auto;
}

.line-height-sm {
  line-height: $line-height-sm;
}

.text-underline {
  text-decoration: underline;
}

.border-bottom-md-0 {
  @include media-breakpoint-down(sm) {
    border-bottom-color: transparent !important;
  }
}

.close {
  position: relative;
  z-index: 20;
  opacity: 1;
}

.break-word {
  word-wrap: break-word;
  word-break: break-word;
}

.font-size-normal {
  font-size: 1rem !important;
}

.bc-mof-logo {
  width: 11rem;
}

// Text truncate sizes
@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size, $length in $pixelsizes {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .text-truncate#{$infix}-#{$size} {
        max-width: $length !important;
      }
    }
  }
}
